import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "../Css/Style.css";
import BannerImg from "../images/banner.webp";
import { Link } from "react-router-dom";

/**
 * @author
 * @function Banner
 **/

const Banner = (props) => {
  return (
    <section className="">
        <Container>
            <Row className="Banner">
                <div className="col-md-6">
                    <h1 className="Banner_h1_dark">The Vital Role of <br/>
                        Reliable <span className="Banner_h1_light">Highway <br/>
                        Infrastructure</span></h1>
                        <p>Building Connections, Driving Progress</p>
                        <p>Shree Sagar & Sons: Your go-to for cutting-edge geosynthetic solutions, 
                        revolutionizing erosion control, crash barriers, and road signage with 
                        unmatched quality and personalized service</p>
                        
                    <Link to="About_us" class=" banner_btn">Read More</Link>
                </div>
                <div className="col-md-6"><img src={BannerImg} className="Bannerimg" /></div>
            </Row>
        </Container>
    </section>
  );
};

export default Banner;
