import React from "react";
import "../Css/Style.css";
import Header from "../component/Header";
import Banner from "../component/Banner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/road.png";
import icon5 from "../images/fabractions.png";
import icon6 from "../images/block.png";
import Mission from "../images/Mission.png";
import Vision from "../images/Vision.png";
// import Team1 from "../images/team1.png";
// import Team2 from "../images/team2.png";
// import Team3 from "../images/team3.png";
// import Team4 from "../images/team4.png";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import Clients from "../component/Clients";
import ServicesHome from "../component/ServicesHome";



/**
 * @author
 * @function Home
 **/

const Home = (props) => {
  return (
    <section className="">
        <Header />
        <Banner />

        <Container>
            <div className="pointers_bg">
                <Row>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/Erosion_Control"><img src={icon1 } alt="" /></Link>
                      <div className="Home_products_text"><Link to="/Erosion_Control">Erosion Control for <br/>Highway Embankment</Link></div>
                    </div>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/Crash_Barrier"><img src={icon2 } alt="" />
                      <div className="Home_products_text">Friction Slab Crash Barrier</div>
                    </Link>
                    </div>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/REWall"><img src={icon3 } alt="" />
                      <div className="Home_products_text">RE Wall</div>
                    </Link>
                    </div>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/Road_signage"><img src={icon4 } alt="" />
                      <div className="Home_products_text">Road Signage</div>
                    </Link>
                    </div>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/Fabrication"><img src={icon5 } alt="" />
                      <div className="Home_products_text">Fabrication</div>
                    </Link>
                    </div>
                    <div className="col-md-2 text-center text-white">
                    <Link to="/REBlock"><img src={icon6 } alt="" />
                      <div className="Home_products_text">RE Block</div>
                    </Link>
                    </div>
                </Row>
            </div>
        </Container>

        <Container className="mission_section">
          <Row>
            <div className="col-md-6">
              <div class="card">
                <div class="card-body">
                  <Row>
                    <div className="col-md-4"><img src={Mission } alt="Mission" /></div>
                    <div className="col-md-8">
                      <h4>Our Mission</h4>
                      <p>At Shree Sagar & Sons, our mission is to provide our 
                        clients with the most advanced and cost-effective 
                        geosynthetic solutions available, along with the highest 
                        levels of customer service and satisfaction.</p>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                  <div class="card-body">
                    <Row>
                      <div className="col-md-4"><img src={Vision } alt="Vision" /></div>
                      <div className="col-md-8">
                        <h4>Our Vision</h4>
                        <p>Our vision is to become the premier global provider of 
                            geosynthetic solutions, setting the standard for excellence 
                            in the industry and leading the way in innovation and 
                            sustainability.</p>
                      </div>
                    </Row>
                  </div>
                </div>
            </div>
          </Row>
        </Container>

        {/* <Container>
            <div className="team_bg">
                <Row>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team1 } alt="" />
                      <h4>Mr. Bharat Bhushan Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team2 } alt="" />
                      <h4>Mr. Rajesh Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team3 } alt="" />
                      <h4>Mr. Sushil Agarwal</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team4 } alt="" />
                      <h4>Mr. Mudit Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                </Row>

                <Row className="team_pt">
                  <div className="col-md-2"><h2>Our <br/>team</h2></div>
                  <div className="col-md-10">
                    <p>At Shree Sagar & Sons, our success hinges on our dedicated team of skilled professionals. With experienced engineers and craftsmen, we tailor geosynthetic solutions to your needs, ensuring top-quality service and satisfaction</p>
                    <Link to="Our_Management_team" class="btn team_btn">Read More</Link>
                  </div>
                </Row>
            </div>
        </Container> */}


        <Container>
            <div className="message_bg">
                <Row>
                    <div className="col-md-7 text-center text-white">
                      &nbsp;
                    </div>

                    <div className="col-md-4 text-left text-white">
                      <h4>Partner message</h4>
                      <p>As the four directors of Shree Sagar & Sons, we are proud to 
                          lead a company that is committed to providing the best possible 
                          geosynthetic solutions to our clients. We believe that our 
                          dedication to excellence and customer service is what sets us 
                          apart from others in the industry.</p>
                    </div>
                </Row>
            </div>
        </Container>

        <section className="pt-5 Product_and_services">
          <Container>
              <Row>
                  <div className="col-md-5">
                      <h1 className="Banner_h1_dark">Products &<br/>
                          <span className="Banner_h1_light">Services <br/></span></h1>
                          <p>Your geosynthetic solution experts! From erosion control to road signage, 
                              we’ve got you covered with top-quality products and turnkey services. 
                              Contact us now and pave the way to success!</p>
                          
                      <Link to="/Product_and_services" type="button" class="btn Product_and_services_btn">Read More</Link>
                  </div>
                  <div className="col-md-7">
                      <ServicesHome />
                  </div>
              </Row>
          </Container>
        </section>


        <Container>
          <Clients />
        </Container>



        <Footer />

    </section>
  );
};

export default Home;
