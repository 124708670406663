import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ourteam from './pages/Ourteam';
import Aboutus from './pages/Aboutus';
import Product_and_services from './pages/ProductServices';
import ErosionControl from './pages/ErosionControl';
import CrashBarrier from './pages/CrashBarrier';
import REWall from './pages/REWall';
import Road_signage from './pages/Road_signage';
import Fabrication_indo from './pages/Fabrication';
import REBlock from './pages/REBlock';
import Approved_certificate from './pages/Approved_certificate';
import Work_order_Slope from './pages/Work_order_Slope';
import Work_order_Re_Wall from './pages/Work_order_Re_Wall';
import FSCB from './pages/FSCB';
import { useEffect } from 'react';
import ScrollToTop from './component/ScrollTop';
import Careers from './pages/Careers';
import { ContactUs } from './pages/ContactUs';
import Ongoing_Project_List from './pages/Ongoing_project_list';
import Complete_Project_list from './pages/complete_project_list';


function App() {


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Our_Management_team" element={<Ourteam />} />
        <Route path="/About_us" element={<Aboutus />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/Product_and_services" element={<Product_and_services />} />
        <Route path="/Erosion_Control" element={<ErosionControl />} />
        <Route path="/Crash_Barrier" element={<CrashBarrier />} />
        <Route path="/REWall" element={<REWall />} />
        <Route path="/Road_signage" element={<Road_signage />} />
        <Route path="/Fabrication" element={<Fabrication_indo />} />
        <Route path="/Approved_certificate" element={<Approved_certificate />} />
        <Route path="/Work_order_Slope" element={<Work_order_Slope />} />
        <Route path="/Work_order_Re_Wall" element={<Work_order_Re_Wall />} />
        <Route path="/FSCB" element={<FSCB />} />
        <Route path="/Ongoing_project_list" element={<Ongoing_Project_List />} />
        <Route path="/REBlock" element={<REBlock />} />
        <Route path="/Completeprojectlist" element={<Complete_Project_list />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
