import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Table from 'react-bootstrap/Table';

import SurveyComponent from "../component/SurveyComponent";
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from "react-router-dom";

/**
 * @author
 * @function Aboutus
 **/

function Careers () {

    const [lgCertificate2, setCertificate2] = useState(false);

  


  return (
    <>
    
    <div className="">

        <Header />

        <Container className="About_us">
            <div className="text-center"><h1 className="page_heading2"><span className="Banner_h1_light">Careers</span></h1>
                <h2>Job Opportunities</h2>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Designation</th>
                    <th>Experience</th>
                    <th>Qualification</th>
                    <th>Expertise</th>
                    <th>Location</th>
                    <th>Vacancy</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Site Incharge</td>
                    <td>Minimum 4 to 5 years </td>
                    <td>Diploma/B.Tech in Civil Engineering</td>
                    <td>RE Wall</td>
                    <td>Pan India </td>
                    <td>3 vacancies</td>
                    <td className="apply_now text-center"><Link onClick={() => setCertificate2(true)} class="btn">Apply Now</Link></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                    <td>Site Engineer</td>
                    <td>1 to 3 years</td>
                    <td>Diploma/B.Tech in Civil Engineering</td>
                    <td>RE Wall</td>
                    <td>Pan India</td>
                    <td>6 vacancies</td>
                    <td className="apply_now text-center"><Link onClick={() => setCertificate2(true)} class="btn">Apply Now</Link></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                    <td>Site Supervisor/Jr. Engineer</td>
                    <td>1 to 3 years</td>
                    <td>ITI/Diploma</td>
                    <td>RE Wall</td>
                    <td>Pan India </td>
                    <td>8 Vacancies</td>
                    <td className="apply_now text-center"><Link onClick={() => setCertificate2(true)} class="btn">Apply Now</Link></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                    <td>Site Accountant</td>
                    <td>1 to 3 years</td>
                    <td>ITI/Diploma</td>
                    <td>Tally/Excel</td>
                    <td>Jammu</td>
                    <td>1 Vacancy</td>
                    <td className="apply_now text-center"><Link onClick={() => setCertificate2(true)} class="btn">Apply Now</Link></td>
                    </tr>
                </tbody>
            </Table>


            {/* <iframe width="640px" height="480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=HoZMTcNoB0umadIZL6DgQFCzNJKcczBCum85CSydIG5UMEhBSkVPV1NWT0o0NjNaNTE4S1kyOUpWTC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0"  allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}


        </Container>

        <div> <img src={FooterRoad} className="footer_road" alt="" ></img> </div>

        <Footer />


        {/* Certificates2 */}
        <Modal size="" show={lgCertificate2} onHide={() => setCertificate2(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <SurveyComponent />
            </Modal.Body>
        </Modal>
        
    </div>
    </>
  );
};

export default Careers;
