import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import rewall3 from "../images/RE_wall/pic_1.webp";
import rewall4 from "../images/RE_wall/pic_2.webp";
import rewall6 from "../images/RE_wall/pic_3.webp";
import rewall7 from "../images/RE_wall/pic_4.webp";
import rewall8 from "../images/RE_wall/pic_5.webp";
import rewall9 from "../images/RE_wall/pic_6.webp";
import rewall10 from "../images/RE_wall/re_wall01.webp";
import rewall11 from "../images/RE_wall/re_wall02.webp";
import rewall12 from "../images/RE_wall/re_wall03.webp";
import rewall13 from "../images/RE_wall/re_wall04.webp";
// import rewall14 from "../images/RE_wall/re_wall05.webp";
// import rewall15 from "../images/RE_wall/re_wall06.webp";
// import rewall16 from "../images/RE_wall/re_wall07.webp";
import rewall17 from "../images/RE_wall/re_wall08.webp";
import rewall18 from "../images/RE_wall/re_wall09.webp";
import rewall19 from "../images/RE_wall/re_wall10.webp";








/**
 * @author
 * @function REWall
 **/

const REWall = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center Re_wall_banner"><h1>RE Wall</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
                At Shree Sagar & Sons, we are committed to providing the highest quality crash barriers lor highways. Our crash barriers are designed to absorb and dissipate the energy of a vehicle impact, helping to protect drivers and pedestrians from serious injury or death. We are experienced in providing customized crash barriers that meet the unique needs of your environment, and our team of engineers is committed to ensuring optimal performance and longterm reliability.
            </p>
        </Container>

        <Container>
        <Row>
        <div className="col-md-12">
              <h3 className="gallery_heading">RE Wall</h3>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img src={rewall10}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />

              <img src={rewall11}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />

<img src={rewall9}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              


              
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">

            

{/* <img src={rewall13}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              /> */}



            
              <img src={rewall19}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={rewall6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
              

              
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">

            <img src={rewall12}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />



<img src={rewall17}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />

<img src={rewall18}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />


<img src={rewall3}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />


{/* <img src={rewall8}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              /> */}
              
              

              
            </div>
          </Row>
        </Container>
        

        <Container className="Projectslider">
          <Servicesslide />
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default REWall;
