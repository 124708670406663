import React from "react";
import Container from 'react-bootstrap/Container';
import FooterLogo from "../images/footer_logo.png";
import Location from "../images/location.png";
import Call from "../images/call.png";
import Row from 'react-bootstrap/Row';

/**
 * @author
 * @function Footer
 **/

const Footer = (props) => {
  return (
    <div>
    <section className="Footer_bg">
        <Container>
            <Row>
                <div className="col-md-3"><img src={FooterLogo} className="footer_logo" alt="" /> </div>
                <div className="col-md-3">
                    <h5><img src={Location} className="" alt="" /> &nbsp;&nbsp; Corporate Office:</h5>
                    <ul className="Footer_bg_list">
                        <li>Plot No. A-40, I-Thum, Unit No. 730<br/>
                        Tower-B, 7th Floor, Sector-62, Noida,<br/>
                        U.P - 201301 </li>
                    </ul>
                </div>
                <div className="col-md-3 head_office">
                    <h5 className="head_office">Head Office:</h5>
                    <ul className="Footer_bg_list">
                        <li>Loha Mandi, Hisar, Haryana - 125001</li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5><img src={Call} alt="" className="" /> &nbsp;&nbsp; Contact No:</h5>
                    <ul className="Footer_bg_list">
                        <li>+91 92151 01444</li> 
                        <li>+91 97111 99105</li>
                    </ul>
                </div>
            </Row>
        </Container>
    </section>

    <div className="footer_bottom">
        <p>© 2024 Shree Sagar and sons | Handcrafted by SHS IMPIRE</p>
    </div>
    </div>
  );
};

export default Footer;
