import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';


function Ongoing_Project_List() {

  return (
    <>
        <div>
            <Header />


        <Container className="About_us">
            <div className="text-center"><h1 className="page_heading2">Ongoing   <span className="Banner_h1_light">Projects </span> List</h1>
                <h2>Building Connections, Driving Progress</h2>
            </div>
        </Container>

            <Container className="services_inner">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Project Code</th>
                        <th>Client</th>
                        <th>Project Name</th>
                        <th>Scope of Work</th>
                        <th>Total Scope</th>
                        <th>Unit</th>
                        <th>Starting Date</th>
                        <th>Status of Work</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Rs-002</td>
                        <td>Jalprakash Associates Limited</td>
                        <td>Varanasi-Gorakhpur Highway Project</td>
                        <td>FSCB Casting & Erection</td>
                        <td>4.00</td>
                        <td>Crore</td>
                        <td>Jan-22</td>
                        <td>In-progress</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>SSS-003</td>
                        <td>Himalaya Construction Company</td>
                        <td>Ahemdabad Oholera Project</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>75,000.00</td>
                        <td>Sqm</td>
                        <td>Jan-22</td>
                        <td>In-progress</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>SSS-004</td>
                        <td>RP Infraventure Pvt. Ltd.</td>
                        <td>Hardoi Lucknow Pkg 3, Uttar Pradesh</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>58,000.00</td>
                        <td>Sqm</td>
                        <td>Dec-22</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>SSS-007</td>
                        <td>Brij Gopal Construction Co. Pvt. Ltd.</td>
                        <td>Gohana-Sonipat Pkg 2, Haryana</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>45,000.00</td>
                        <td>Sqm</td>
                        <td>Dec-22</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>SSS-008</td>
                        <td>GR - Infraprojects Ltd.</td>
                        <td>Ujjain-Badnwar, Ujjain, Madhaya Pradesh</td>
                        <td>Block Casting</td>
                        <td>160,000.00  </td>
                        <td>Sqm</td>
                        <td>Nov-22</td>
                        <td>Casting In progress</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>SSS-010</td>
                        <td>PNC Infratech Ltd.</td>
                        <td>Hardoi Lucknow Pkg 3, Uttar Pradesh</td>
                        <td>Execution of RE wall work</td>
                        <td>54,000.00</td>
                        <td>Sqm</td>
                        <td>Jan-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>SSS-011</td>
                        <td>RK Infra</td>
                        <td>Rupnagar Punjab, Punjab</td>
                        <td>Design,Supply & Execution of RE Wall</td>
                        <td>100,000.00</td>
                        <td>Sqm</td>
                        <td>Jan-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>SSS-012</td>
                        <td>MRG Constructions</td>
                        <td>Bongaigaon to Baihata Bypass, Guwahati, Assam</td>
                        <td>Design,Supply & Execution of RE Wall</td>
                        <td>60,000.00</td>
                        <td>Sqm</td>
                        <td>Feb-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>SSS-013</td>
                        <td>PNC lnfratech Limited</td>
                        <td>Lucknow Kanpur Pkg 1, Uttar Pradesh</td>
                        <td>FSCB Casting & Erection</td>
                        <td>7,000.00</td>
                        <td>Rmt</td>
                        <td>Feb-23</td>
                        <td>Casting In-progress</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>SSS-014</td>
                        <td>Satish Aggarwal & Co.</td>
                        <td>Lakhanpur-Samba NH-44, Jammu & Kashmir</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>20,000.00</td>
                        <td>Sqm</td>
                        <td>Feb-23</td>
                        <td>Casting In-progress</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>SSS-015</td>
                        <td>TATA Projects Ltd.</td>
                        <td>Garhmukteshwar-Meerut Road, Uttar Pradesh</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>17,000.00</td>
                        <td>Sqm</td>
                        <td>Feb-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>SSS-016</td>
                        <td>Vishwa Samudra Engineering Pvt. Ltd.</td>
                        <td>DAK-15 Expressway, Jammu</td>
                        <td>FSCB Casting & Erection</td>
                        <td>10,000.00</td>
                        <td>Rmt</td>
                        <td>Feb-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>SSS-017</td>
                        <td>Radhakrishan Infra Developers Pvt. Ltd.</td>
                        <td>Ramseen-Bhinmal-Raniwara Section, Rajasthan</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>18,000.00</td>
                        <td>Sqm</td>
                        <td>Mar-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>SSS-018</td>
                        <td>Badri Rai & Co.</td>
                        <td>Madhavpur Tippling Road, Assam</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>4,200.00</td>
                        <td>Sqm</td>
                        <td>Mar-23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>SSS-019</td>
                        <td>Ceigall India Pvt. Ltd.</td>
                        <td>Malout Abohar, Punjab</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>  </td>
                        <td>Sqm</td>
                        <td>Mar-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>SSS-020</td>
                        <td>GR lnlraprojects</td>
                        <td>Ujjain-Badnwar, Ujjain, Madhaya Pradesh</td>
                        <td>Block Casting</td>
                        <td>80,000.00</td>
                        <td>Sqm</td>
                        <td>Apr-23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>SSS-021</td>
                        <td>RK Infra</td>
                        <td>Rupnagar Punjab, Punjab</td>
                        <td>FSCB Casting & Erection</td>
                        <td>18,000.00</td>
                        <td>Rmt</td>
                        <td>Apr-23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>SSS-022</td>
                        <td>KKB</td>
                        <td>Karnataka</td>
                        <td>FSCB Casting & Erection</td>
                        <td>1,500.00</td>
                        <td>Rmt</td>
                        <td>Apr-23</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>SSS-023</td>
                        <td>Megha Engineers Pvt. Ltd.</td>
                        <td>Kurnool,Andhra Pradesh</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>55,000.00</td>
                        <td>Sqm</td>
                        <td>Apr-23</td>
                        <td>Casting In-progress</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>SSS-024</td>
                        <td>Patel Infrastructure Ltd.</td>
                        <td>SCP, Tamilnadu</td>
                        <td>Design, Supply & Execution of BlockWall</td>
                        <td>14,000.00</td>
                        <td>Sqm</td>
                        <td>May-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>SSS-025</td>
                        <td>SRC Infra Developers</td>
                        <td>Ballari,Karnataka</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>55,000.00</td>
                        <td>Sqm</td>
                        <td>May-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>SSS-026</td>
                        <td>Dhariwal Buildtech Ltd.</td>
                        <td>Raha to Kathaiatali Section,Guwahati, Assam</td>
                        <td>FSCB Casting & Erection</td>
                        <td>  </td>
                        <td>Rmt</td>
                        <td>May-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>SSS-028</td>
                        <td>SRC Infra Developers</td>
                        <td>Ballari,Karnataka</td>
                        <td>FSCB Casting & Erection</td>
                        <td>12,000.00</td>
                        <td>Rmt</td>
                        <td>May-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>SSS-031</td>
                        <td>Jandu Construction Pvt. Ltd.</td>
                        <td>Jabalpur Ringroad Project, Madhya Pradesh</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>25,000.00</td>
                        <td>Sqm</td>
                        <td>May-23</td>
                        <td>Erection In-progress</td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>SSS-032</td>
                        <td>Krishna Constellation Pvt. Ltd.</td>
                        <td>Mundka, Delhi</td>
                        <td>Pedestrian Gaurad Rail</td>
                        <td>26,000.00</td>
                        <td>Rmt</td>
                        <td>Jun-23</td>
                        <td>On Hold</td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>SSS-033</td>
                        <td>Krishna Constellation Pvt. Ltd.</td>
                        <td>Mundka, Delhi</td>
                        <td>Interlocking Tile</td>
                        <td>80,000.00</td>
                        <td>Sqm</td>
                        <td>Jun-23</td>
                        <td>On Hold</td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>SSS-034</td>
                        <td>Ceigall India Pvt. Ltd.</td>
                        <td>Dabwali- Bhatinda Section</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>58,000.00</td>
                        <td>Sqm</td>
                        <td>Jun-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>SSS-036</td>
                        <td>Patel Infrastructure Ltd (DVE-30)</td>
                        <td>Ahemdabad</td>
                        <td>Design, Supply & Execution of Block RE wall</td>
                        <td>18,000.00</td>
                        <td>Sqm</td>
                        <td>Jun-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td>SSS-037</td>
                        <td>Regal Infra Pvt Ltd</td>
                        <td>Moga</td>
                        <td>Design, Supply & Execution of Block RE wall</td>
                        <td>10,000.00</td>
                        <td>Sqm</td>
                        <td>Jul-23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>SSS-038</td>
                        <td>Patel Infrastructure Ltd (Pkg-7)</td>
                        <td>Hardoi, Ganga Expressway</td>
                        <td>Supply & Execution of Slope Protection</td>
                        <td>52,000.00</td>
                        <td>Sqm</td>
                        <td>Jul-23</td>
                        <td>Work In-Progress</td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>SSS-039</td>
                        <td>Patel Infrastructure Ltd ( Pkg-8)</td>
                        <td>Unnao, Ganga Expressway</td>
                        <td>Supply & Execution of Slope Protection</td>
                        <td>260,000.00</td>
                        <td>Sqm</td>
                        <td>Jul-23</td>
                        <td>Work In-Progress</td>
                    </tr>
                    <tr>
                        <td>32</td>
                        <td>SSS-040</td>
                        <td>Patel Infrastructure Ltd (CTP)</td>
                        <td>Tamil Nadu</td>
                        <td>Supply & Block Erection work</td>
                        <td>13,000.00</td>
                        <td>Sqm</td>
                        <td>Jul-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>SSS-041</td>
                        <td>Brij Gopal Construction Co. Pvt Ltd</td>
                        <td>Odisha</td>
                        <td>Design, Supply & Execution of RE wall</td>
                        <td>57,000.00</td>
                        <td>Sqm</td>
                        <td>Jul-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>SSS-044</td>
                        <td>Gawar Construction Ltd</td>
                        <td>Kanakpura</td>
                        <td>FSCB Casting & Erection</td>
                        <td>  </td>
                        <td>Rmt</td>
                        <td>Sep-23</td>
                        <td>Work In-Progress</td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>SSS-045</td>
                        <td>Gawar Construction Ltd</td>
                        <td>Kanakpura</td>
                        <td>Block Erection</td>
                        <td>  </td>
                        <td>Sqm</td>
                        <td>Sep-23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>SSS-046</td>
                        <td>Vishwa Samudra Engineering Pvt Ltd</td>
                        <td>Paravor</td>
                        <td>Foot Over Bridge (FOB)</td>
                        <td>480.00</td>
                        <td>MT</td>
                        <td>Sep-23</td>
                        <td>Work In-Progress</td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>SSS-047</td>
                        <td>Ganpati Builders</td>
                        <td>Assam -Dibrugarh</td>
                        <td>Re wall</td>
                        <td>10,000.00</td>
                        <td>Sqmt</td>
                        <td>10/06/23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>SSS-048</td>
                        <td>Baba construction Pvt .Ltd</td>
                        <td>baliya - UP</td>
                        <td>Re wall</td>
                        <td>35,000.00</td>
                        <td>Sqmt</td>
                        <td>11/01/23</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>SSS-049</td>
                        <td>Vishwa Smudra & engineering Pvt .Ltd</td>
                        <td>Kerala -Kannur</td>
                        <td>Block</td>
                        <td>50,000.00</td>
                        <td>Sqm</td>
                        <td>31-10-2023</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>SSS-050</td>
                        <td>PSK infrasturctures and project Pvt. Ltd.</td>
                        <td>Odisha - Chandikhole</td>
                        <td>Re wall</td>
                        <td>66,162.00</td>
                        <td>Sqmt</td>
                        <td>23-11-2023</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>SSS-051</td>
                        <td>Patel infrastructure ltd.</td>
                        <td>UP - Unnao/Hardoi</td>
                        <td>Block</td>
                        <td>13,800.00</td>
                        <td>Sqmt</td>
                        <td>11/12/23</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>SSS-052</td>
                        <td>Dhariwal buildtech</td>
                        <td>Bihar-Parsarma</td>
                        <td>Re wall</td>
                        <td>150,000.00</td>
                        <td>Sqmt</td>
                        <td>01/06/24</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>SSS-053</td>
                        <td>SBCC bulidcon pvt. Ltd.</td>
                        <td>Bihar Gaya</td>
                        <td>Block</td>
                        <td>1,10,000.00</td>
                        <td>Sqmt</td>
                        <td>23-12-2023</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>SSS-054</td>
                        <td>Megha Engeering & infrasturcture ltd.</td>
                        <td>Telangana - Kurnool</td>
                        <td>FSCB</td>
                        <td>15,000.00</td>
                        <td>Rmt</td>
                        <td>28-10-2023</td>
                        <td>Casting In-Progress</td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>SSS-055</td>
                        <td>Vishwa Smudra & engineering pvt. Ltd.</td>
                        <td>Sitapur, UP</td>
                        <td>Road Sighages</td>
                        <td>1,51,90,215.00</td>
                        <td>Amt</td>
                        <td>23-02-2024</td>
                        <td>Erection In-Progress</td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>SSS-059</td>
                        <td>Sunrise construction compay</td>
                        <td>Bhiwani- Hansi</td>
                        <td>Rs wall</td>
                        <td>  </td>
                        <td>Sqmt</td>
                        <td>15-03-2024</td>
                        <td>Work In Progress</td>
                    </tr>
                    

                </tbody>
            </Table>

            </Container>

            <Footer />
        </div>
    </>
  );
}

export default Ongoing_Project_List;