export const json = {
    "title": "Careers",
    "description": "Job Opportunities",
    "logoPosition": "right",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "text",
        "name": "question1",
        "title": "First Name",
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question2",
        "title": "Last Name"
       },
       {
        "type": "text",
        "name": "question3",
        "title": "Email",
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question4",
        "title": "Phone",
        "isRequired": true
       },
       {
        "type": "file",
        "name": "question5",
        "title": "Resume",
        "isRequired": true
       }
      ],
      "title": "Basic Info"
     }
    ]
   }