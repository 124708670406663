import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Row from 'react-bootstrap/Row';
import client1 from "../images/client1.jpg";
import client2 from "../images/client2.jpg";
import client3 from "../images/client3.jpg";
import client4 from "../images/client4.jpg";
import client5 from "../images/client5.jpg";
import client6 from "../images/client6.jpg";
import client7 from "../images/client7.jpg";
import client8 from "../images/client8.jpg";
import client9 from "../images/client9.jpg";
import client10 from "../images/client10.jpg";
import client11 from "../images/client11.jpg";
import client12 from "../images/client12.jpg";
import client13 from "../images/client13.jpg";
import client14 from "../images/client14.jpg";
import client15 from "../images/client15.jpg";
import client16 from "../images/client16.jpg";
import client17 from "../images/client17.jpg";
import client18 from "../images/client18.jpg";
import client19 from "../images/client19.jpg";
import client20 from "../images/client20.jpg";
import "../Css/Style.css";


function Clients() {

  return (
    <>
    <Row className='Client_section'>
        <div className='col-md-5 Clients_bg'>
            <div className='Client_heading'>Clientele</div>
        </div>
        <div className='col-md-7'>
            <OwlCarousel className='owl-theme' loop margin={10} autoplay  dots={true} items={3} autoplayTimeout={1200} smartSpeed={1000} >
                    <div className='item Client_img'>
                        <img src={client1} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client2} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client3} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client4} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client5} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client6} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client7} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client8} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client9} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client10} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client11} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client12} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client13} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client14} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client15} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client16} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client17} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client18} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client19} alt='' />
                    </div>
                    <div class='item Client_img'>
                        <img src={client20} alt='' />
                    </div>
            </OwlCarousel>
        </div>
    </Row>
    </>
  );
}

export default Clients;