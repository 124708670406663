import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';


function Complete_Project_list() {

  return (
    <>
        <div>
            <Header />


        <Container className="About_us">
            <div className="text-center"><h1 className="page_heading2">Completed   <span className="Banner_h1_light">Projects </span> List</h1>
                <h2>Building Connections, Driving Progress</h2>
            </div>
        </Container>

            <Container className="services_inner">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Project Code</th>
                        <th>Client</th>
                        <th>Project Name</th>
                        <th>Scope of Work</th>
                        <th>Total Scope</th>
                        <th>Unit</th>
                        <th>Starting Date</th>
                        <th>Status of Work</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Rs-001</td>
                        <td>Jalprakash Associates Limited</td>
                        <td>Varanasi-Gorakhpur Highway Project</td>
                        <td>Providing and Fixing of Signages and Studs</td>
                        <td>3.50</td>
                        <td>Crore</td>
                        <td>Jan-22</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>SSS-005</td>
                        <td>Dhariwal Buildtech Ltd.</td>
                        <td>Raha to Kathaiatali Section, Guwahati, Assam</td>
                        <td>Design,Supply & Execution of RE wall</td>
                        <td>50,000.00</td>
                        <td>Sqm</td>
                        <td>Dec-22</td>
                        <td>Completed</td>
                    </tr>
                     
                    <tr>
                        <td>3</td>
                        <td>SSS-006</td>
                        <td>RP Infraventure Pvt. Ltd.</td>
                        <td>Fathehabad Road, Agra, Uttar Pradesh</td>
                        <td>Design & Supply of RE wall</td>
                        <td>1,500.00</td>
                        <td>Sqm</td>
                        <td>Dec-23</td>
                        <td>Completed</td>
                    </tr>
                   
                    <tr>
                        <td>4</td>
                        <td>SSS-009</td>
                        <td>GR - Infraprojects Ltd.</td>
                        <td>MABH Ludhiana-Rupnagar, Punjab</td>
                        <td>Block Casting</td>
                        <td>117,376.00</td>
                        <td>Sqm</td>
                        <td>Jan-23</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>SSS-027</td>
                        <td>Patel Infrastructure Ltd. (DVE-30)</td>
                        <td>Ahemdabad</td>
                        <td>Plank Casting</td>
                        <td>5,000.00</td>
                        <td>Cum</td>
                        <td>May-23</td>
                        <td>Completed</td>
                    </tr>
                    
                    <tr>
                        <td>6</td>
                        <td>SSS-030</td>
                        <td>GR lnfraprojects</td>
                        <td>Ujjain</td>
                        <td>FSCB Casting & Erection</td>
                        <td>20,000.00</td>
                        <td>Rmt</td>
                        <td>May-23</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>SSS-035</td>
                        <td>Swastik Infra Developers</td>
                        <td>NH-161, KKRP, Hydrabad</td>
                        <td>Slope Protection Work</td>
                        <td>15,000.00</td>
                        <td>Sqm</td>
                        <td>Jun-23</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>SSS-042</td>
                        <td>Civil Mantra</td>
                        <td>  </td>
                        <td>Design and drawing</td>
                        <td>  </td>
                        <td>  </td>
                        <td>Jul-23</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>SSS-043</td>
                        <td>Vishwa Samudra Engineering Pvt Ltd</td>
                        <td>Srikakulam, Andhra</td>
                        <td>Accropod Mould Fabrication</td>
                        <td>150.00</td>
                        <td>Nos.</td>
                        <td>Aug-23</td>
                        <td>Completed</td>
                    </tr>
                    
                    <tr>
                        <td>10</td>
                        <td>SSS-056</td>
                        <td>SNAL design & drawing</td>
                        <td>Harpalpur-MP</td>
                        <td>Design</td>
                        <td>  </td>
                        <td>  </td>
                        <td>01/05/24</td>
                        <td>Completed</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>SSS-057</td>
                        <td>Ifcon design & drawing</td>
                        <td>  </td>
                        <td>Design</td>
                        <td>  </td>
                        <td>  </td>
                        <td>02/05/24</td>
                        <td>Completed</td>
                    </tr>
                    
                </tbody>
            </Table>

            </Container>

            <Footer />
        </div>
    </>
  );
}

export default Complete_Project_list;