import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import crashbarrier1 from "../images/Crash_Barrier/crash_1.webp";
import crashbarrier2 from "../images/Crash_Barrier/crash_2.webp";
import crashbarrier3 from "../images/Crash_Barrier/crash_3.webp";
import crashbarrier4 from "../images/Crash_Barrier/crash_4.webp";
import crashbarrier5 from "../images/Crash_Barrier/crash_5.webp";
import crashbarrier6 from "../images/Crash_Barrier/crash_6.webp";
import crashbarrier7 from "../images/Crash_Barrier/crash_7.webp";




/**
 * @author
 * @function CrashBarrier
 **/

const CrashBarrier = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center Crash_Barrier_banner"><h1>Friction Slab Crash Barrier</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
            At Shree Sagar & Sons, we are committed to providing the highest quality crash barriers for highways. Our crash barriers are designed to absorb and dissipate the energy of a vehicle impact, helping to protect drivers and pedestrians from serious injury or death. We are experienced in providing customized crash barriers that meet the unique needs of your environment, and our team of engineers is committed to ensuring optimal performance and longterm reliability.
            </p>
        </Container>


        <Container>
        <Row>
        <div className="col-md-12">
              <h3 className="gallery_heading">Friction Slab Crash Barrier</h3>
            </div>
            <div className="col-lg-6 col-md-12 mb-6 mb-lg-0">
              

<img src={crashbarrier2}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

                <img src={crashbarrier5}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              /> 
              <img src={crashbarrier7}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              /> 

              </div>

            <div className="col-lg-6 mb-6 mb-lg-0">

            <img src={crashbarrier6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              /> 

            {/* <img src={crashbarrier1}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />   */}

              <img src={crashbarrier4}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              /> 
           
            </div>
            

        
          </Row>
        </Container>


        <Container className="Projectslider">
          <Servicesslide />
        </Container>

        

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>
        

        <Footer />
    </div>
  );
};

export default CrashBarrier;
