import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

/**
 * @author
 * @function Home
 **/

const Header = (props) => {
  return (
    <section className="header_main">
        <div className="top_menu_bg fixed-top">
            <Container>
                <Row>
                    <div className="col-md-6 text-left text-white top_contact_info"><i className="fa fa-envelope top_fa_icon" aria-hidden="true"></i> <strong><Link to="mailto:shreesagarsons@gmail.com" target="_blank">shreesagarsons@gmail.com</Link>, <Link to="mailto:sushil@shreesagar.net" target="_blank">sushil@shreesagar.net</Link></strong></div>
                    <div className="col-md-6 top_mob_number text-white top_contact_info"><i className="fa fa-phone top_fa_icon2" aria-hidden="true"></i> <strong><Link to="tel:+919215101444" target="_blank">+91 92151 01444,</Link> <Link to="tel:+919711199105" target="_blank">+91 97111 99105</Link></strong></div>
                </Row>
            </Container>
        </div>
        <Navbar expand="lg" className="bg-body-tertiary fixed-top top_menu_margin">
            <Container>
                <Navbar.Brand><Link to="/"><img src={Logo } alt="" className="logo" /> </Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link><Link to="/">Home</Link></Nav.Link>
                    <Nav.Link><Link to="/About_us">About Us</Link></Nav.Link>
                        <NavDropdown className="dropdownt" title="Products & Services" id="basic-nav-dropdown">
                        <NavDropdown.Item>
                            <Link to="/Product_and_services">All Products</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/Erosion_Control">
                            <Link to="/Erosion_Control">Erosion Control for Highway Embankment</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Crash_Barrier">Friction Slab Crash Barrier</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/REWall">RE Wall</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Road_signage">Road Signage</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Fabrication">Fabrication</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/REBlock">RE Block</Link>
                        </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="dropdownt" title="Certificates" id="basic-nav-dropdown">
                        <NavDropdown.Item to="/">
                            <Link to="/Approved_certificate">Completion Certificate</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Work_order_Re_Wall">Work Order - RE Wall</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/FSCB">Work Order - Friction, Slab & Signages</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Work_order_Slope">Work Order - Slope</Link>
                        </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="dropdownt" title="Projects list" id="basic-nav-dropdown">
                        <NavDropdown.Item to="/">
                            <Link to="/Ongoing_project_list">Ongoing Projects List</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/Completeprojectlist">Completed Projects List</Link>
                        </NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link><Link to="/Ongoing_project_list">Ongoing project list</Link></Nav.Link> */}
                        <Nav.Link><Link to="/Careers">Careers</Link></Nav.Link>
                        <Nav.Link><Link to="/Contact">Contact Us</Link></Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
      
    </section>
  );
};

export default Header;
