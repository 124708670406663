import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import fabrication1 from "../images/Fabrication/fab_1.webp";
import fabrication2 from "../images/Fabrication/fab_2.webp";
import fabrication3 from "../images/Fabrication/fab_3.webp";
import fabrication4 from "../images/Fabrication/fab_4.webp";
import fabrication5 from "../images/Fabrication/fab_5.webp";
import fabrication6 from "../images/Fabrication/fab_6.webp";
import fabrication7 from "../images/Fabrication/fab_7.webp";
import fabrication8 from "../images/Fabrication/fab_8.webp";
import fabrication9 from "../images/Fabrication/fab_9.webp";
import fabrication10 from "../images/Fabrication/fab_10.webp";
import fabrication11 from "../images/Fabrication/fab_11.webp";
import fabrication12 from "../images/Fabrication/fab_12.webp";
import fabrication13 from "../images/Fabrication/fab_13.webp";



/**
 * @author
 * @function Fabrication_indo
 **/

const Fabrication_indo = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center Fabrication_banner"><h1>Fabrication</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
            Shree Sagar & Sons: Expert Fabrication for infrastructure Excellence.<br/>
            <strong>INDOPOD -</strong> Tailored Coastal Armor Solutions- In the dynamic world of civil engineering and construction, Shree Sagar & Sons proudly presents "INDOPOD, a cutting-edge solution in coastal and marine engineering. These bespoke concrete armor units are the guardians of our coastlines, shielding against erosion and the relentless forces of waves and currents. The hallmark of INDOPOD is its adaptability, with each block meticulously crafted to harmonize with project-specific needs and diverse wave dynamics. This innovative twist on the classic accropode block guarantees a resilient coastal barrier, meticulously tailored to withstand the unique challenges of each project.<br/><br/>
            <strong>PGR (Pedestrian Guard Rail) -</strong> Safety in Every Step -At Shree Sagar & Sons, our commitment extends to pedestrian safety, exemplified through our impeccable fabrication of Pedestrian Guard Rails. These robust metal barriers, expertly crafted through a series of stages including design, material selection, precision cutting, welding, surtace treatment, and rigorous quality control, provide a safeguarding boundary for pedestrians across roadways, walkways, and construction zones. Count on us for your safety needs.<br/><br/>
            <strong>FOB (Foot Over Bridge) - </strong> Elevate pedestrian safety with our Foot Over Bridge (FOB) fabrication
expertise. These elevated walk-ways ensure secure passage over roads, railways, and other
obstacles. Our comprehensive process encompasses design, material selection, precision fabrication,
meticulous assembly, quality control, surface treatment, and safe installation, all while
adhering to regulations and compliance standards. Shree Sagar & Sons bridges the path to urban
mobility and pedestrian well-being. Trust Shree Sagar & Sons for precision, safety, and excellence
in fabrication.
            </p>
        </Container>

        <Container>
          
        <Row>
            <div className="col-md-12">
              <h3 className="gallery_heading">Fabrication</h3>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              
              <img src={fabrication3}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={fabrication5}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={fabrication6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />


<img src={fabrication10}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={fabrication13}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />



              
            
            </div>

            <div className="col-lg-8 mb-8 mb-lg-0">
            <img src={fabrication4}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

<img src={fabrication7}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              /> 

<img src={fabrication9}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />


<img src={fabrication8}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={fabrication12}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />



              
            </div>

           
          </Row>
        </Container>

        <Container className="Projectslider">
          <Servicesslide />
        </Container>


        

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default Fabrication_indo;
