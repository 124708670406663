import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import ErosionControl1 from "../images/Erossion_control/img_1.webp";
import ErosionControl3 from "../images/Erossion_control/img_3.webp";
import ErosionControl4 from "../images/Erossion_control/img_4.webp";
import ErosionControl5 from "../images/Erossion_control/img_5.webp";
import ErosionControl6 from "../images/Erossion_control/img_6.webp";
import ErosionControl7 from "../images/Erossion_control/img_7.webp";
import ErosionControl8 from "../images/Erossion_control/img_8.webp";
import ErosionControl9 from "../images/Erossion_control/img_9.webp";



/**
 * @author
 * @function ErosionControl
 **/

const ErosionControl = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center ErosionControl_banner"><h1>Erosion Control for Highway Embankment</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
            At Shree Sagar & Sons, we are proud to offer top-tier erosion control services for highway embankments. Our specialized team of engineers is experienced in providing comprehensive 
            solutions that mitigate the effects of erosion and protect embankments from the erosive forces of wind and water. We have the expertise to design, install, and maintain embankment 
            inforcement systems that are tailored to the unique needs of your environment, ensuring optimal performance and long-term durability.
            </p>
        </Container>

        <Container>
        <Row>
        <div className="col-md-12">
              <h3 className="gallery_heading">Erosion Control for Highway Embankment</h3>
            </div>
            <div className="col-lg-6 col-md-12 mb-6 mb-lg-0">
              <img src={ErosionControl3}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              <img src={ErosionControl5}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

<img src={ErosionControl7}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />

<img src={ErosionControl9}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              
            </div>

            <div className="col-lg-6 mb-6 mb-lg-0">
              <img src={ErosionControl1}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

              <img src={ErosionControl4}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

<img src={ErosionControl6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

<img src={ErosionControl8}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />


            </div>

            <div className="col-lg-2 mb-2 mb-lg-0">
              

              
            </div>
          </Row>
        </Container>

        <Container className="Projectslider">
          <Servicesslide />
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default ErosionControl;
