import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Row from 'react-bootstrap/Row';

import FooterRoad from "../images/footer_road.jpg";
import fss_1 from "../images/Work_order_fss_1.webp";
import fss_2 from "../images/Work_order_fss_2.webp";
import fss_3 from "../images/Work_order_fss_3.webp";
import fss_4 from "../images/Work_order_fss_4.webp";
import fss_5 from "../images/Work_order_fss_5.webp";



import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from "react-router-dom";

/**
 * @author
 * @function FSCB
 **/

function FSCB() {
    const [lgworl1, setworl1] = useState(false);
    const [lgworl2, setworl2] = useState(false);
    const [lgworl3, setworl3] = useState(false);
    const [lgworl4, setworl4] = useState(false);
    const [lgworl5, setworl5] = useState(false);
  
    return (
      <>
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center ErosionControl_banner"><h1>Work Order - Friction, Slab & Signages</h1>
            </div>

            <Row className="Certificate_page_margin">
                <div className="col-md-1"></div>
            <div className="col-md-2">
                    <Link onClick={() => setworl1(true)}><img src={fss_1} alt="Certificate 2" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setworl2(true)}><img src={fss_2} alt="Certificate 3" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setworl3(true)}><img src={fss_3} alt="Certificate 4" className="Certificate_img"></img></Link>
                </div>  

                <div className="col-md-2">
                    <Link onClick={() => setworl4(true)}><img src={fss_4} alt="Certificate 5" className="Certificate_img"></img></Link>
                </div>  

                <div className="col-md-2">
                    <Link onClick={() => setworl5(true)}><img src={fss_5} alt="Certificate 6" className="Certificate_img"></img></Link>
                </div> 



            </Row>
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />

        {/* Certificates2 */}
        <Modal size="" show={lgworl1} onHide={() => setworl1(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={fss_1} alt="Certificate 2" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates3 */}
        <Modal size="" show={lgworl2} onHide={() => setworl2(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={fss_2} alt="Certificate 3" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates4 */}
        <Modal size="" show={lgworl3} onHide={() => setworl3(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={fss_3} alt="Certificate 4" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates5 */}
        <Modal size="" show={lgworl4} onHide={() => setworl4(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={fss_4} alt="Certificate 5" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates6 */}
        <Modal size="" show={lgworl5} onHide={() => setworl5(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={fss_5} alt="Certificate 6" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>


    </div>
    </>
  );
}

export default FSCB;
