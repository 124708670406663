import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import reblock1 from "../images/reblock_1.webp";
import reblock2 from "../images/reblock_2.webp";
import reblock3 from "../images/reblock_3.webp";
import reblock4 from "../images/reblock_4.webp";
import reblock5 from "../images/reblock_5.webp";
import reblock6 from "../images/re_block/re_block01.webp";
import reblock7 from "../images/re_block/re_block02.webp";
import reblock8 from "../images/re_block/re_block03.webp";



/**
 * @author
 * @function REBlock
 **/

const REBlock = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center RE_Block_banner"><h1>RE Block</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
            Reinforced Earth Block (REB), also known as Reinforced Earth or Mechanically Stabilized Earth (MSE), is a construction technique used in civil engineering for building retaining walls, bridge abutments, and other earth retaining structures. It combines the strength of soil and reinforcements to create stable and durable structures.<br/>
            <strong>Here's how the Reinforced Earth Block system typically works:</strong><br/>
            <strong>Blocks: </strong>Concrete blocks or precast concrete panels are used as facing elements. These blocks are typically rectangular or trapezoidal in shape and have a special profile on the backside to interlock with the soil reinforcements.<br/>
            <strong>Backfill: </strong> The space between the facing elements is filled with compacted granular soil, often referred to as backfill or infill material. This backfill material is placed in thin layers and compacted to the required density.<br/>
            <strong>Interlocking: </strong> As the backfill material is placed and compacted, the blocks interlock with the soil reinforcements. This creates a stable and cohesive structure.<br/>
            <strong>Drainage: </strong> Proper drainage is essential to prevent water buildup behind the wall, which could lead to instability. Drainage systems, such as weep holes or perforated pipes, are typically incorporated into the design.<br/>
            </p>
        </Container>

        <Container>
        <Row>
        <div className="col-md-12">
              <h3 className="gallery_heading">RE Block</h3>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              
              <img src={reblock1}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />

              <img src={reblock2}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

                

               <img src={reblock7}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />          
            </div>

            <div className="col-lg-8 mb-8 mb-lg-0">

            <img src={reblock6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

<img src={reblock5}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />


           
             
              <img src={reblock4}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

<img src={reblock8}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              /> 
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              
            </div>
          </Row>
        </Container>

        <Container className="Projectslider">
          <Servicesslide />
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default REBlock;
