import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Services1 from "../images/service1.png";
import Services2 from "../images/service2.png";
import Services3 from "../images/service3.png";
import Services4 from "../images/service4.png";
import Services5 from "../images/service5.png";
import Services6 from "../images/service6.png";
import "../Css/Style.css";
import { Link } from 'react-router-dom';


function ServicesHome() {

  return (
    <>
        <OwlCarousel className='owl-theme' loop margin={10}  autoplay dots={false} items={3}>
                <div className='item Client_img'>
                    <Link to="Erosion_Control"><img src={Services1} alt='' />
                        <div className='Services_slide_heading'> Erosion Control for Highway Embankment</div>
                    </Link>
                </div>
                <div class='item Client_img'>
                    <Link to="Crash_Barrier"><img src={Services2} alt='' />
                        <div className='Services_slide_heading'>Friction Slab Crash Barrier</div>
                    </Link>
                </div>
                <div class='item Client_img'>
                    <Link to="REWall"><img src={Services3} alt='' />
                        <div className='Services_slide_heading'>RE Wall</div>
                    </Link>
                </div>
                <div class='item Client_img'>
                    <Link to="Road_signage"><img src={Services4} alt='' />
                        <div className='Services_slide_heading'>Road Signage</div>
                    </Link>
                </div>
                <div class='item Client_img'>
                    <Link to="Fabrication"><img src={Services5} alt='' />
                        <div className='Services_slide_heading'>Fabrication</div>
                    </Link>
                </div>
                <div class='item Client_img'>
                    <Link to="REBlock"><img src={Services6} alt='' />
                        <div className='Services_slide_heading'>RE Block</div>
                    </Link>
                </div>
        </OwlCarousel>
    </>
  );
}

export default ServicesHome;