import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";

import FooterRoad from "../images/footer_road.jpg";
import Servicesslide from "../component/Servicesslide";
import Row from 'react-bootstrap/Row';
import road1 from "../images/Road_signages/img_1.webp";
import road2 from "../images/Road_signages/img_2.webp";
import road3 from "../images/Road_signages/img_3.webp";
import road4 from "../images/Road_signages/img_4.webp";
import road5 from "../images/Road_signages/img_5.webp";
import road6 from "../images/Road_signages/img_6.webp";
import road7 from "../images/Road_signages/img_7.webp";
import road8 from "../images/Road_signages/img_8.webp";
import road9 from "../images/Road_signages/img_9.webp";
import road10 from "../images/Road_signages/img_10.webp";
import road11 from "../images/Road_signages/img_11.webp";
import road12 from "../images/Road_signages/img_12.webp";
import road13 from "../images/Road_signages/img_13.webp";
import road14 from "../images/Road_signages/img_14.webp";



/**
 * @author
 * @function Road_signage
 **/

const Road_signage = (props) => {
  return (
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center Road_signage_banner"><h1>Road Signage</h1>
                <h2>Products & Services</h2>
            </div>

            <p>
                At Shree Sagar & Sons, we are proud to offer a comprehensive range of road signage solutions. Our experienced team of engineers is capable of designing and installing road signage that meets the unique needs of your environment, ensuring optimal visibility and long-term durability. We use only the highest quality materials, and our rigorous quality control standards ensure that your road signage is built to last.
            </p>
        </Container>


        <Container>
        <Row>
        <div className="col-md-12">
              <h3 className="gallery_heading">Road Signage</h3>
            </div>
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <img src={road5}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              <img src={road2}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />              

               <img src={road7}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road8}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />   

              <img src={road13}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />  
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img src={road6}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

              <img src={road1}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road10}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road11}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road12}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />                         

              </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img src={road4}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road3}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img src={road9}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />   

              <img src={road14}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />  

                        
            </div>
          </Row>
        </Container>


        <Container className="Projectslider">
          <Servicesslide />
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default Road_signage;
