import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from "../component/Header";
import Footer from "../component/Footer";

import Team1 from "../images/team1.png";
import Team2 from "../images/team2.png";
import Team3 from "../images/team3.png";
import Team4 from "../images/team4.png";
import FooterRoad from "../images/footer_road.jpg";

/**
 * @author
 * @function Ourteam
 **/

const Ourteam = (props) => {
  return (
    <div>
        <Header />

        <Container>
            <div className="team_bg">
                <Row>
                    <div className="text-center">
                      <h1 className="page_heading">Our <span className="Banner_h1_light">Management </span> team</h1>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team1 } alt="" />
                      <h4>Mr. Bharat Bhushan Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team2 } alt="" />
                      <h4>Mr. Rajesh Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team3 } alt="" />
                      <h4>Mr. Sushil Agarwal</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                    <div className="col-md-3 text-center text-white">
                      <img src={Team4 } alt="" />
                      <h4>Mr. Mudit Jain</h4>
                      <p>Lorem ipsum dolor sit amet,<br/>consectetuer adipiscing elit. Aenean</p>
                    </div>
                </Row>
            </div>

            <p>
            At Shree Sagar & Sons, we understand that our success is dependent on our team of highly talented and dedicated professionals. Our team includes experienced engineers, skilled 
            craftsmen, and knowledgeable customer service representatives, all committed to providing our clients with the highest level of service and satisfaction. Our engineers are experienced
            in designing and installing customized geosynthetic solutions that meet the unique needs of your environment, and our craftsmen are dedicated to providing the highest quality 
            materials and workmanship. We are committed to providing our clients with the best possible service, and our team of professionals is what makes it possible.<br/><br/>
            Furthermore, our commitment to delivering unparalleled service extends beyond our talented professionals. We prioritize continuous investment in cutting-edge technology and state-
            of-the-art facilities to uphold our reputation for excellence. From conceptualization to execution, we strive for perfection in every project, setting new standards in the industry and 
            ensuring the success of our clients’ endeavors.
            </p>
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />
    </div>
  );
};

export default Ourteam;
