import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Row from 'react-bootstrap/Row';

import FooterRoad from "../images/footer_road.jpg";
import slope_1 from "../images/Work_order_slope_1.webp";
import slope_2 from "../images/Work_order_slope_2.webp";
import slope_3 from "../images/Work_order_slope_3.jpg";


import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from "react-router-dom";

/**
 * @author
 * @function Work_order_Slope
 **/


function Work_order_Slope() {
    const [lgworld1, setworld1] = useState(false);
    const [lgworld2, setworld2] = useState(false);
    const [lgworld3, setworld3] = useState(false);



    return (
        <>
      <div>
          <Header />
  
          <Container className="services_inner">
              <div className="text-center ErosionControl_banner"><h1>Work Order - Slope</h1>
              </div>
  
              <Row className="Certificate_page_margin">
                <div className="col-md-4"></div>
              <div className="col-md-2">
                      <Link onClick={() => setworld1(true)}><img src={slope_1} alt="Certificate 1" className="Certificate_img"></img></Link>
                  </div>

                  <div className="col-md-2">
                      <Link onClick={() => setworld2(true)}><img src={slope_2} alt="Certificate 2" className="Certificate_img"></img></Link>
                  </div>

                  {/* <div className="col-md-2">
                      <Link onClick={() => setworld3(true)}><img src={slope_3} alt="Certificate 3" className="Certificate_img"></img></Link>
                  </div> */}
  
            
  
  
              </Row>
          </Container>
  
          <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>
  
          <Footer />
  
          {/* Certificates1 */}
          <Modal size="" show={lgworld1} onHide={() => setworld1(false)} aria-labelledby="example-modal-sizes-title-lg" >
              <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg text-center">
                      
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                  <img src={slope_1} alt="Certificate 1" className="Certificate_zoom_img"></img>
              </Modal.Body>
          </Modal>

          {/* Certificates2 */}
          <Modal size="" show={lgworld2} onHide={() => setworld2(false)} aria-labelledby="example-modal-sizes-title-lg" >
              <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg text-center">
                      
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                  <img src={slope_2} alt="Certificate 2" className="Certificate_zoom_img"></img>
              </Modal.Body>
          </Modal>

          {/* Certificates3 */}
          <Modal size="" show={lgworld3} onHide={() => setworld3(false)} aria-labelledby="example-modal-sizes-title-lg" >
              <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg text-center">
                      
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                  <img src={slope_3} alt="Certificate 3" className="Certificate_zoom_img"></img>
              </Modal.Body>
          </Modal>
  
          
  
  
      </div>
      </>
    );
  }

export default Work_order_Slope;