import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Row from 'react-bootstrap/Row';

import FooterRoad from "../images/footer_road.jpg";
import map1 from "../images/map_07.png";


/**
 * @author
 * @function Aboutus
 **/

function Aboutus () {

  


  return (
    <>
    
    <div className="">
    

        <Header />

        <Container className="About_us">
            <div className="text-center"><h1 className="page_heading2">The Vital Role of Reliable <span className="Banner_h1_light">Highway <br/>Infrastructure </span></h1>
                <h2>Building Connections, Driving Progress</h2>
            </div>

          <Row>
            <div className="col-md-6"><img alt="Map" src={map1} className="map" /></div>
            <div className="col-md-6">
            <p>
            Shree Sagar & Sons is a premier provider of Sophisticated Geosynthetic Solutions, RE Wall, Slope Protection, and Ground Improvement Solution offering a comprehensive array of 
specialized services to address the erosion control, crash barrier, and road signage needs of clients throughout the region. Our esteemed team of engineers is renowned for its ability to 
deliver turnkey solutions on an EPC (Engineering, Procurement, and Construction) basis, including design and drawing, casting, erection, shifting, supplying, and installation, thus 
streamlining the ROI of our clients by saving both cost and time.<br/><br/>

Our expansive inventory includes a wide array of leading-edge products, such as Geostrip, Geogrid, Geocomposite, Geotextile, Geocell, EPDM Pad, and Cavity, among other advanced 
materials. With our unrivaled expertise and commitment to excellence, Shree Sagar & Sons has established itself as a leader in the geosynthetic industry. We provide customized solutions 
to the clients for their specific requirements. Our team is highly customer-oriented and strives to provide the best quality work and customer satisfaction. We have vast experience in the 
field and understand the needs of the clients and have the expertise to deliver and exceed their expectations. At Shree Sagar & Sons, we believe in providing the best services, innovative 
solutions, and value for money to our clients. Our aim is to maintain a long-term relationship with our clients and ensure that all our clients get the best services.
            </p>
            </div>
          </Row>

            
        </Container>

        <div> <img src={FooterRoad} className="footer_road" alt="" ></img> </div>

        <Footer />
    </div>
    </>
  );
};

export default Aboutus;
