import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Row from 'react-bootstrap/Row';

import FooterRoad from "../images/footer_road.jpg";

import Work_order_re_wall_1 from "../images/Work_order_re_wall_1.webp";
import Work_order_re_wall_2 from "../images/Work_order_re_wall_2.webp";
import Work_order_re_wall_3 from "../images/Work_order_re_wall_3.webp";
import Work_order_re_wall_4 from "../images/Work_order_re_wall_4.webp";
import Work_order_re_wall_5 from "../images/Work_order_re_wall_5.webp";
import Work_order_re_wall_6 from "../images/Work_order_re_wall_6.webp";
import Work_order_re_wall_7 from "../images/Work_order_re_wall_7.webp";
import Work_order_re_wall_8 from "../images/Work_order_re_wall_8.webp";
import Work_order_re_wall_9 from "../images/Work_order_re_wall_9.webp";
import Work_order_re_wall_10 from "../images/Work_order_re_wall_10.webp";
import Work_order_re_wall_11 from "../images/Work_order_re_wall_11.webp";
import Work_order_re_wall_12 from "../images/Work_order_re_wall_12.webp";
import Work_order_re_wall_13 from "../images/Work_order_re_wall_13.webp";
import Work_order_re_wall_14 from "../images/Work_order_re_wall_14.webp";
import Work_order_re_wall_15 from "../images/Work_order_re_wall_15.webp";
import Work_order_re_wall_16 from "../images/Work_order_re_wall_16.webp";
import Work_order_re_wall_17 from "../images/Work_order_re_wall_17.webp";
import Work_order_re_wall_18 from "../images/Work_order_re_wall_18.webp";
import Work_order_re_wall_19 from "../images/Work_order_re_wall_19.webp";
import Work_order_re_wall_20 from "../images/Work_order_re_wall_20.webp";
import Work_order_re_wall_21 from "../images/Work_order_re_wall_21.webp";
import Work_order_re_wall_22 from "../images/Work_order_re_wall_22.webp";




import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from "react-router-dom";

/**
 * @author
 * @function Work_order_RE_Wall
 **/

function Work_order_Re_Wall() {
    const [lgWork_order_re_wall_1, setWork_order_re_wall_1] = useState(false);
    const [lgWork_order_re_wall_2, setWork_order_re_wall_2] = useState(false);
    const [lgWork_order_re_wall_3, setWork_order_re_wall_3] = useState(false);
    const [lgWork_order_re_wall_4, setWork_order_re_wall_4] = useState(false);
    const [lgWork_order_re_wall_5, setWork_order_re_wall_5] = useState(false);
    const [lgWork_order_re_wall_6, setWork_order_re_wall_6] = useState(false);
    const [lgWork_order_re_wall_7, setWork_order_re_wall_7] = useState(false);
    const [lgWork_order_re_wall_8, setWork_order_re_wall_8] = useState(false);
    const [lgWork_order_re_wall_9, setWork_order_re_wall_9] = useState(false);
    const [lgWork_order_re_wall_10, setWork_order_re_wall_10] = useState(false);
    const [lgWork_order_re_wall_11, setWork_order_re_wall_11] = useState(false);
    const [lgWork_order_re_wall_12, setWork_order_re_wall_12] = useState(false);
    const [lgWork_order_re_wall_13, setWork_order_re_wall_13] = useState(false);
    const [lgWork_order_re_wall_14, setWork_order_re_wall_14] = useState(false);
    const [lgWork_order_re_wall_15, setWork_order_re_wall_15] = useState(false);
    const [lgWork_order_re_wall_16, setWork_order_re_wall_16] = useState(false);
    const [lgWork_order_re_wall_17, setWork_order_re_wall_17] = useState(false);
    const [lgWork_order_re_wall_18, setWork_order_re_wall_18] = useState(false);
    const [lgWork_order_re_wall_19, setWork_order_re_wall_19] = useState(false);
    const [lgWork_order_re_wall_20, setWork_order_re_wall_20] = useState(false);
    const [lgWork_order_re_wall_21, setWork_order_re_wall_21] = useState(false);
    const [lgWork_order_re_wall_22, setWork_order_re_wall_22] = useState(false);
  
    return (
      <>
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center ErosionControl_banner"><h1>Work Order - RE Wall</h1>
            </div>

            <Row className="Certificate_page_margin">

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_1(true)}><img src={Work_order_re_wall_1} alt="Work order re wall 1" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_2(true)}><img src={Work_order_re_wall_2} alt="Work order re wall 2" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_3(true)}><img src={Work_order_re_wall_3} alt="Work order re wall 3" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_4(true)}><img src={Work_order_re_wall_4} alt="Work order re wall 4" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_5(true)}><img src={Work_order_re_wall_5} alt="Work order re wall 5" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_6(true)}><img src={Work_order_re_wall_6} alt="Work order re wall 6" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_7(true)}><img src={Work_order_re_wall_7} alt="Work order re wall 7" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_8(true)}><img src={Work_order_re_wall_8} alt="Work order re wall 8" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_9(true)}><img src={Work_order_re_wall_9} alt="Work order re wall 9" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_10(true)}><img src={Work_order_re_wall_10} alt="Work order re wall 10" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_11(true)}><img src={Work_order_re_wall_11} alt="Work order re wall 11" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_12(true)}><img src={Work_order_re_wall_12} alt="Work order re wall 12" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_13(true)}><img src={Work_order_re_wall_13} alt="Work order re wall 13" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_14(true)}><img src={Work_order_re_wall_14} alt="Work order re wall 14" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_15(true)}><img src={Work_order_re_wall_15} alt="Work order re wall 15" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_16(true)}><img src={Work_order_re_wall_16} alt="Work order re wall 16" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_17(true)}><img src={Work_order_re_wall_17} alt="Work order re wall 17" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_18(true)}><img src={Work_order_re_wall_18} alt="Work order re wall 18" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_19(true)}><img src={Work_order_re_wall_19} alt="Work order re wall 19" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_20(true)}><img src={Work_order_re_wall_20} alt="Work order re wall 20" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_21(true)}><img src={Work_order_re_wall_21} alt="Work order re wall 21" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setWork_order_re_wall_22(true)}><img src={Work_order_re_wall_22} alt="Work order re wall 22" className="Certificate_img"></img></Link>
                </div>




            </Row>
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />



        {/* Work order re wall 1 */}
        <Modal size="" show={lgWork_order_re_wall_1} onHide={() => setWork_order_re_wall_1(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_1} alt="Certificate 1" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 2 */}
        <Modal size="" show={lgWork_order_re_wall_2} onHide={() => setWork_order_re_wall_2(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_2} alt="Certificate 2" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 3 */}
        <Modal size="" show={lgWork_order_re_wall_3} onHide={() => setWork_order_re_wall_3(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_3} alt="Certificate 3" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 4 */}
        <Modal size="" show={lgWork_order_re_wall_4} onHide={() => setWork_order_re_wall_4(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_4} alt="Certificate 4" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 5 */}
        <Modal size="" show={lgWork_order_re_wall_5} onHide={() => setWork_order_re_wall_5(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_5} alt="Certificate 5" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 6 */}
        <Modal size="" show={lgWork_order_re_wall_6} onHide={() => setWork_order_re_wall_6(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_6} alt="Certificate 6" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 7 */}
        <Modal size="" show={lgWork_order_re_wall_7} onHide={() => setWork_order_re_wall_7(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_7} alt="Certificate 7" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 8 */}
        <Modal size="" show={lgWork_order_re_wall_8} onHide={() => setWork_order_re_wall_8(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_8} alt="Certificate 8" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 9 */}
        <Modal size="" show={lgWork_order_re_wall_9} onHide={() => setWork_order_re_wall_9(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_9} alt="Certificate 9" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 10 */}
        <Modal size="" show={lgWork_order_re_wall_10} onHide={() => setWork_order_re_wall_10(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_10} alt="Certificate 10" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 11 */}
        <Modal size="" show={lgWork_order_re_wall_11} onHide={() => setWork_order_re_wall_11(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_11} alt="Certificate 11" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 12 */}
        <Modal size="" show={lgWork_order_re_wall_12} onHide={() => setWork_order_re_wall_12(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_12} alt="Certificate 12" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 13 */}
        <Modal size="" show={lgWork_order_re_wall_13} onHide={() => setWork_order_re_wall_13(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_13} alt="Certificate 13" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 14 */}
        <Modal size="" show={lgWork_order_re_wall_14} onHide={() => setWork_order_re_wall_14(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_14} alt="Certificate 14" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 15 */}
        <Modal size="" show={lgWork_order_re_wall_15} onHide={() => setWork_order_re_wall_15(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_15} alt="Certificate 15" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 16 */}
        <Modal size="" show={lgWork_order_re_wall_16} onHide={() => setWork_order_re_wall_16(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_16} alt="Certificate 16" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 17 */}
        <Modal size="" show={lgWork_order_re_wall_17} onHide={() => setWork_order_re_wall_17(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_17} alt="Certificate 17" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 18 */}
        <Modal size="" show={lgWork_order_re_wall_18} onHide={() => setWork_order_re_wall_18(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_18} alt="Certificate 18" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 19 */}
        <Modal size="" show={lgWork_order_re_wall_19} onHide={() => setWork_order_re_wall_19(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_19} alt="Certificate 19" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 20 */}
        <Modal size="" show={lgWork_order_re_wall_20} onHide={() => setWork_order_re_wall_20(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_20} alt="Certificate 20" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 21 */}
        <Modal size="" show={lgWork_order_re_wall_21} onHide={() => setWork_order_re_wall_21(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_21} alt="Certificate 21" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Work order re wall 22 */}
        <Modal size="" show={lgWork_order_re_wall_22} onHide={() => setWork_order_re_wall_22(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Work_order_re_wall_22} alt="Certificate 22" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>


    </div>
    </>
  );
}

export default Work_order_Re_Wall;
