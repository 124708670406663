import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_9yi2u0n', 'template_kzg9kau', form.current, {
        publicKey: 'Zc_nouGi-w6GFTAOL',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div>
      <Header />

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1882.8575348596255!2d77.3730324048135!3d28.626219238431844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5336becb191%3A0xa89caf8bfb9e7068!2siThum-Noida!5e0!3m2!1sen!2sin!4v1714452576375!5m2!1sen!2sin" width="100%" height="450" border="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    {/* <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />

      <label>file</label>
      <input type="file" name="myfile"></input>



      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}

    <Container>
      <Row className='contact_form'>
      <div className='col-md-6'>
        <h4><strong>Corporate Office:</strong></h4>
          <ul className="list-unstyled">
            <li><i className="fa fa-phone"></i> <strong>Phone:</strong> +91 97111 99105</li>
            <li><i className="fa fa-envelope"></i> <strong>Email:</strong>  <a href="mailto:sushil@shreesagar.com">sushil@shreesagar.net</a></li>
            <li><i className="fa fa-map-marker"></i> <strong>Address:</strong> Plot No. A-40, I-Thum, Unit No. 730 Tower-B, 7th Floor, Sector-62, Noida, U.P - 201301</li>
          </ul>


          <h4><strong>Head Office:</strong></h4>
          <ul className="list-unstyled">
            <li><i className="fa fa-phone"></i> <strong>Phone:</strong> +91 92151 01444, </li>
            <li><i className="fa fa-envelope"></i> <strong>Email:</strong> <a href="mailto:shreesagarsons@gmail.com">shreesagarsons@gmail.com</a></li>
            <li><i className="fa fa-map-marker"></i> <strong>Address:</strong> Loha Mandi, Hisar, Haryana - 125001</li>
          </ul>
      </div>

        <div className='col-md-6'>
        <form ref={form} onSubmit={sendEmail}>
          <div className="row mb-4">
            <div className="col">
              <div data-mdb-input-init className="form-outline">
                <label className="form-label" for="form6Example1">First name</label>
                <input type="text" name="user_name" className="form-control" />
              </div>
            </div>
            <div className="col">
              <div data-mdb-input-init className="form-outline">
                <label className="form-label" for="form6Example2">Last name</label>
                <input type="text" name="" className="form-control" />
              </div>
            </div>
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form6Example3">Company name</label>
            <input type="text" name="company" className="form-control" />
          </div>


          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form6Example5">Email</label>
            <input type="email" name="email" className="form-control" />
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form6Example6">Phone</label>
            <input type="number" name="phone" className="form-control" />
          </div>

          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" for="form6Example7">Additional information</label>
            <textarea className="form-control" name="additionalinformation" rows="4"></textarea>
          </div>


          <input type="submit" value="Send" className='contant_submit_btn' />
        </form>
        </div>
      </Row>
    </Container>
    
    <Footer />
    </div>
  );
};
