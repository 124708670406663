import React from "react";
import Container from 'react-bootstrap/Container';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Row from 'react-bootstrap/Row';

import FooterRoad from "../images/footer_road.jpg";
import Certificate1 from "../images/Completion_certificate1.webp";
import Certificate2 from "../images/Completion_certificate2.webp";
import Certificate3 from "../images/Completion_certificate3.webp";
import Certificate4 from "../images/Completion_certificate4.webp";
import Certificate5 from "../images/Completion_certificate5.webp";
import Certificate6 from "../images/Completion_certificate6.webp";
import Certificate7 from "../images/Completion_certificate7.webp";




import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from "react-router-dom";

/**
 * @author
 * @function Approved_certificate
 **/

function Approved_certificate() {
    const [lgShow, setLgShow] = useState(false);
    const [lgCertificate2, setCertificate2] = useState(false);
    const [lgCertificate3, setCertificate3] = useState(false);
    const [lgCertificate4, setCertificate4] = useState(false);
    const [lgCertificate5, setCertificate5] = useState(false);
    const [lgCertificate6, setCertificate6] = useState(false);
    const [lgCertificate7, setCertificate7] = useState(false);
  
    return (
      <>
    <div>
        <Header />

        <Container className="services_inner">
            <div className="text-center ErosionControl_banner"><h1>Completion Certificate</h1>
            </div>

            <Row className="Certificate_page_margin">
                <div className="col-md-2">
                    <Link onClick={() => setLgShow(true)}><img src={Certificate1} alt="Certificate 1" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate2(true)}><img src={Certificate2} alt="Certificate 2" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate3(true)}><img src={Certificate3} alt="Certificate 3" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate4(true)}><img src={Certificate4} alt="Certificate 4" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate5(true)}><img src={Certificate5} alt="Certificate 5" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate6(true)}><img src={Certificate6} alt="Certificate 6" className="Certificate_img"></img></Link>
                </div>

                <div className="col-md-2">
                    <Link onClick={() => setCertificate7(true)}><img src={Certificate7} alt="Certificate 7" className="Certificate_img"></img></Link>
                </div>


            </Row>
        </Container>

        <div> <img src={FooterRoad} className="footer_road"  alt="" ></img> </div>

        <Footer />

        {/* Certificates1 */}
        <Modal size="" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate1} alt="Certificate 1" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates2 */}
        <Modal size="" show={lgCertificate2} onHide={() => setCertificate2(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate2} alt="Certificate 2" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates3 */}
        <Modal size="" show={lgCertificate3} onHide={() => setCertificate3(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate3} alt="Certificate 3" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates4 */}
        <Modal size="" show={lgCertificate4} onHide={() => setCertificate4(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate4} alt="Certificate 4" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates5 */}
        <Modal size="" show={lgCertificate5} onHide={() => setCertificate5(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate5} alt="Certificate 5" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates6 */}
        <Modal size="" show={lgCertificate6} onHide={() => setCertificate6(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate6} alt="Certificate 6" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>

        {/* Certificates7 */}
        <Modal size="" show={lgCertificate7} onHide={() => setCertificate7(false)} aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg text-center">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img src={Certificate7} alt="Certificate 7" className="Certificate_zoom_img"></img>
            </Modal.Body>
        </Modal>


    </div>
    </>
  );
}

export default Approved_certificate;
