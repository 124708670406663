import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from "../component/Header";
import Footer from "../component/Footer";
import FooterRoad from "../images/footer_road.jpg";
import ErosionControl from "../images/ErosionControl.webp";
import CrashBarrier from "../images/CrashBarrier.webp";
import Fabrication from "../images/Fabrication.webp";
import RoadSignage from "../images/RoadSignage.webp";
import RSWall from "../images/RSWall.webp";
import Block from "../images/Block.webp";
import { Link } from "react-router-dom";


/**
 * @author
 * @function Product_and_services
 **/

const Product_and_services = (props) => {
  return (
    <div>
        <Header />

        <Container className="About_us">
            <div className="text-center"><h1 className="page_heading">Products <span className="Banner_h1_light">& Services <br/> </span></h1>
            </div>

            <p className="mb-4">
            Welcome to Shree Sagar & Sons, your one-stop source for all of your geosynthetic needs. Our expert team of engineers has the knowledge and experience to provide you with the highest 
quality solutions for erosion control, crash barrier, RS wall, road signage, and FSCB on EPC (Engineering, Procurement, and Construction) basis. We specialize in providing turnkey 
solutions that save you both time and money, with services including design and drawing, casting, erection, shifting, supply, and installation. We also offer a wide range of materials and 
products, such as Ego-trip, Geogrid, Geocomposite, Geotextile, Geocell, EPDM Pad, and Cavity, to meet all of your geosynthetic requirements. With our commitment to excellence and 
unparalleled expertise, Shree Sagar & Sons is the perfect partner for all of your geosynthetic needs. Contact us today to learn more about how we can help you succeed.
            </p>

            <Row>
                <div className="col-md-4"><Link to="/Erosion_Control" ><img src={ErosionControl} className="Product_and_services_img" alt="Erosion Control" /></Link></div>
                <div className="col-md-4"><Link to="/Crash_Barrier" ><img src={CrashBarrier} className="Product_and_services_img" alt="Crash Barrier" /></Link></div>
                <div className="col-md-4"><Link to="/Fabrication" ><img src={Fabrication} className="Product_and_services_img" alt="Fabrication" /></Link></div>
                <div className="col-md-4"><Link to="/Road_signage" ><img src={RoadSignage} className="Product_and_services_img" alt="Road Signage" /></Link></div>
                <div className="col-md-4"><Link to="/REWall" ><img src={RSWall} className="Product_and_services_img"alt="RE Wall" /></Link></div>
                <div className="col-md-4"><Link to="/REBlock" ><img src={Block} className="Product_and_services_img" alt="RE Block" /></Link></div>
            </Row>
        </Container>

        <div> <img src={FooterRoad} className="footer_road" alt="Road" ></img> </div>

        <Footer />
    </div>
  );
};

export default Product_and_services;
